import React from 'react'
import logosB from '../assets/imgs/logos_lng.png'

const BcxSectionA = () => {
  return (
    <section id="SectionA">
      <div className="container">
        <div className="text">
          <p>
            O GeldCred Feliz é uma solução para auxiliar prestadores de serviços
            e micro e pequenos empresários a transformarem os seus negócios.
          </p>
          <p>
            O objetivo é facilitar o acesso ao crédito, possibilitando
            investimentos em infraestrutura, planejamento de ampliação,
            melhorias de gestão e outras necessidades da empresa.
          </p>
          <p className="maiuscula negrito">
            essa é uma união de esforços entre a Prefeitura de Feliz, a Sicredi
            Pioneira, A RS Garanti e o Sebrae para fortalecer a economia local.
          </p>
        </div>

        <div className="logos">
          <img
            className="parceiros"
            src={logosB}
            alt="Logotipos GeldCred Feliz, Sicredi, Sebrae, RS Garanti, Prefeitura de Feliz"
          />
        </div>
      </div>
    </section>
  )
}

export default BcxSectionA
