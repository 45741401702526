import React from 'react'
import Sebrae from '../assets/imgs/sebrae.png'

const BcxSectionD = () => {
  return (
    <section id="sectionD">
      <div className="container">
        <div className="titulo">
          <h2>
            Uma Parceria com{' '}
            <strong>
              soluções
              <br /> para sua empresa
            </strong>
          </h2>
        </div>

        <div className="conteudoBcxSectionD">
          <div className="header">
            <div className="descricao">
              <img src={Sebrae} alt="Logo da Sebrae" />
              <p>
                O Sebrae está oferecendo três capacitações online GRATUITAS aos
                empreendedores interessados.
              </p>
              <p>
                Mesmo sem efetivação do crédito, você pode ter acesso a
                ferramentas que te ajudam a gerenciar melhor seus negócios.
              </p>
              <p>
                <strong>Aproveite essa oportunidade!</strong>
              </p>
            </div>
          </div>

          <div className="content">
            <div className="col">
              <h3>Capacitações GRATUITAS em Marketing e Vendas</h3>
              <a
                className="btn"
                href="https://digital.sebraers.com.br/produtos/marketing-e-vendas/curso/potencialize-suas-vendas/ "
                target="_blank"
                rel="noopener noreferrer"
              >
                Potencialize suas Vendas
              </a>

              <a
                className="btn"
                href="https://digital.sebraers.com.br/produtos/marketing-e-vendas/curso/marketing-digital-direto-ao-ponto/ "
                target="_blank"
                rel="noopener noreferrer"
              >
                Marketing Digital Direto ao Ponto
              </a>

              <h3>Capacitação GRATUITA em Finanças</h3>
              <a
                className="btn"
                href="https://digital.sebraers.com.br/produtos/financas/curso/custos-industriais-e-preco-de-venda/ "
                target="_blank"
                rel="noopener noreferrer"
              >
                Custos Industriais e Preço de Venda
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BcxSectionD
